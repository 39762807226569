'use client';

import { ReactNode } from 'react';
import config from '@legacy/core/config';
import { CloudinaryContext } from '@legacy-components/ResponsiveImage';

const CloudinaryProvider = ({ children }: { children: ReactNode }) => {
  return (
    <CloudinaryContext.Provider value={config.cloudinary}>
      {children}
    </CloudinaryContext.Provider>
  );
};

export default CloudinaryProvider;
