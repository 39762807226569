import { ThemedSymbolProps } from './typings';
import { Color } from '../../theme';

const CheckOutlined = ({
  theme,
  active,
  color,
  ...svgProps
}: ThemedSymbolProps) => (
  <svg {...svgProps}>
    <g fill={theme.color[active ? color || Color.PRIMARY : Color.GRAY_300]}>
      <path d="M63.8867 127.773C28.6487 127.773 0 99.1246 0 63.8867C0 28.6487 28.6487 0 63.8867 0C99.1246 0 127.773 28.6487 127.773 63.8867C127.773 99.1246 99.1246 127.773 63.8867 127.773ZM63.8867 12.278C35.4423 12.278 12.278 35.442 12.278 63.8867C12.278 92.3314 35.442 115.495 63.8867 115.495C92.3314 115.495 115.495 92.3314 115.495 63.8867C115.495 35.442 92.3314 12.278 63.8867 12.278Z" />
      <path d="M51.0774 88.7703C49.522 88.7703 47.9257 88.1559 46.7391 86.9695C44.3239 84.5543 44.3239 80.667 46.7391 78.293L84.228 40.8041C86.6432 38.3889 90.5305 38.3889 92.9045 40.8041C95.3197 43.2192 95.3197 47.1065 92.9045 49.4805L55.4156 86.9695C54.2291 88.1559 52.6327 88.7703 51.0774 88.7703Z" />
      <path d="M50.2986 87.9926C48.7433 87.9926 47.1469 87.3782 45.9603 86.1918L30.7762 71.0076C28.361 68.5925 28.361 64.7052 30.7762 62.3312C33.1503 59.916 37.0787 59.916 39.4527 62.3312L54.6368 77.5153C57.052 79.9305 57.052 83.8177 54.6368 86.1918C53.4504 87.3782 51.8539 87.9926 50.2986 87.9926Z" />
    </g>
  </svg>
);

export default CheckOutlined;
