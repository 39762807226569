import { useEffect } from 'react';

import { useSignIn } from '@app-lib/authentication/hooks';

import SignIn, { Props as SignInProps } from './SignIn';
import {
  CLIENT_PROFILE_QUERY,
  ClientProfileQueryData,
} from '@app-lib/account/query';
import { useQuery } from '@apollo/react-hooks';
import { Routes } from '@legacy/typings';
import { useRouter } from 'next/navigation';

type Props = Pick<SignInProps, 'initialValues' | 'signInOrSignUpMode'>;

const ConnectedSignIn = ({ initialValues, signInOrSignUpMode }: Props) => {
  const { invalidCredentials, inError, signIn } = useSignIn();
  const { data } = useQuery<ClientProfileQueryData>(CLIENT_PROFILE_QUERY, {});
  const router = useRouter();

  useEffect(() => {
    if (data?.profile) {
      router.push(Routes.HOME);
    }
  }, [data]);

  return (
    <SignIn
      initialValues={initialValues}
      signIn={signIn}
      inError={inError}
      invalidCredentials={invalidCredentials}
      signInOrSignUpMode={!!signInOrSignUpMode}
    />
  );
};

export default ConnectedSignIn;
