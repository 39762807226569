import { ReactElement } from 'react';

import { ThemedSymbolProps } from './typings';

const Placeholder = ({
  theme,
  active,
  color,
  ...svgProps
}: ThemedSymbolProps): ReactElement => (
  <svg {...svgProps}>
    <g />
  </svg>
);

export default Placeholder;
