'use client';
import { ReactNode } from 'react';
import {
  IntlProvider as ReactIntlProvider,
  MessageFormatElement,
} from 'react-intl';
import legacyEn from '@translations/customer-app/en.json';
import legacyFr from '@translations/customer-app/fr.json';

import enTranslations from '@assets/compiled-lang/en.json';
import frTranslations from '@assets/compiled-lang/fr.json';

interface Props {
  children: ReactNode;
  locale: string;
}

const messages: Record<string, Record<string, MessageFormatElement[]>> = {
  fr: { ...frTranslations, ...legacyFr },
  en: { ...enTranslations, ...legacyEn },
};

const defaultRichTextElements = {
  b: (...chunks: ReactNode[]) => <strong>{chunks}</strong>,
  br: () => <span>{'\n'}</span>,
};

const IntlProvider = ({ children, locale }: Props) => {
  return (
    <ReactIntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
