import { FC, ReactElement } from 'react';
import { withTheme } from 'styled-components';

import { Color } from '../../theme';
import { ThemedSymbolProps } from './typings';

const ChefHat: FC<ThemedSymbolProps> = ({
  theme,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  active,
  color,
  ...svgProps
}: ThemedSymbolProps): ReactElement => {
  const currentColor =
    theme.color[active ? color || Color.PRIMARY : Color.GRAY_300];
  return (
    <svg {...svgProps}>
      <g fill={currentColor}>
        <path
          d="M29.6714 5.06474L30.1245 5.73016L30.8713 5.42968C32.0046 4.9737 33.128 4.75 34.375 4.75C39.6967 4.75 44 9.05326 44 14.375C44 18.689 41.1619 22.4157 37.2253 23.5404L36.5 23.7477V24.502V46.5H8.5V24.502V23.7477L7.77472 23.5404C3.83808 22.4157 1 18.689 1 14.375C1 9.05326 5.30326 4.75 10.625 4.75C11.53 4.75 12.322 4.86315 13.2596 5.0964L13.9031 5.25649L14.301 4.72598C15.9873 2.47758 18.816 1 21.875 1C25.1635 1 27.9806 2.58136 29.6714 5.06474Z"
          fill={theme.color[Color.LIGHT]}
          stroke={currentColor}
          stroke-width="2"
        />
        <path d="M15 42.5C14.248 42.5 13.75 41.875 13.75 41.25V30C13.75 29.375 14.248 28.75 15 28.75C15.625 28.75 16.25 29.248 16.25 30V41.25C16.25 41.875 15.752 42.5 15 42.5ZM23.75 41.25V30C23.75 29.375 23.252 28.75 22.5 28.75C21.875 28.75 21.25 29.248 21.25 30V41.25C21.25 41.875 21.748 42.5 22.5 42.5C23.252 42.5 23.75 41.875 23.75 41.25ZM31.25 41.25V30C31.25 29.375 30.752 28.75 30 28.75C29.375 28.75 28.75 29.248 28.75 30V41.25C28.75 41.875 29.248 42.5 30 42.5C30.752 42.5 31.25 41.875 31.25 41.25Z" />
        <path d="M7.5 40H37.5V47.5H7.5V40Z" />
      </g>
    </svg>
  );
};

export default withTheme(ChefHat);
