import { ReactElement } from 'react';

import { SymbolProps } from './typings';

const PaymentCardElo = ({ ...svgProps }: SymbolProps): ReactElement => (
  <svg {...svgProps}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="elo" fillRule="nonzero">
        <path
          d="M40,0 L710,0 C732.1,0 750,17.9 750,40 L750,431 C750,453.1 732.1,471 710,471 L40,471 C17.9,471 0,453.1 0,431 L0,40 C0,17.9 17.9,0 40,0 Z"
          id="Rectangle-1"
          fill="#000000"
        />
        <path
          d="M150.7,170.6 C157.5,168.3 164.8,167.1 172.4,167.1 C205.6,167.1 233.3,190.7 239.6,222 L286.6,212.4 C275.8,159.2 228.8,119.1 172.4,119.1 C159.5,119.1 147.1,121.2 135.5,125.1 L150.7,170.6 Z"
          id="Shape"
          fill="#FFF100"
        />
        <path
          d="M95.2,323 L127,287 C112.8,274.4 103.9,256.1 103.9,235.6 C103.9,215.2 112.8,196.8 127,184.3 L95.2,148.4 C71.1,169.8 55.9,200.9 55.9,235.7 C55.9,270.4 71.1,301.6 95.2,323 Z"
          id="Shape"
          fill="#00A3DF"
        />
        <path
          d="M239.6,249.4 C233.2,280.7 205.6,304.2 172.4,304.2 C164.8,304.2 157.5,303 150.6,300.7 L135.4,346.2 C147,350.1 159.5,352.2 172.4,352.2 C228.8,352.2 275.8,312.2 286.6,259 L239.6,249.4 Z"
          id="Shape"
          fill="#EE4023"
        />
        <g
          id="Group"
          transform="translate(342.000000, 148.000000)"
          fill="#FFFFFF"
        >
          <path
            d="M101.2,133.6 C93.4,141.2 82.9,145.8 71.3,145.6 C63.3,145.5 55.9,143.1 49.7,139.1 L34.1,163.9 C44.8,170.6 57.3,174.6 70.9,174.8 C90.6,175.1 108.6,167.3 121.7,154.6 L101.2,133.6 Z M73,32.5 C33.8,31.9 1.4,63.3 0.8,102.5 C0.6,117.2 4.8,131 12.3,142.4 L141.1,87.3 C133.9,56.4 106.3,33.1 73,32.5 Z M30.3,108.1 C30.1,106.5 30,104.8 30,103.1 C30.4,80 49.4,61.5 72.5,61.9 C85.1,62.1 96.3,67.8 103.8,76.8 L30.3,108.1 Z M181.6,0.5 L181.6,137.8 L205.4,147.7 L194.1,174.8 L170.5,165 C165.2,162.7 161.6,159.2 158.9,155.2 C156.3,151.2 154.3,145.6 154.3,138.2 L154.3,0.5 L181.6,0.5 Z"
            id="Shape"
          />
          <path
            d="M267.5,64 C271.7,62.6 276.1,61.9 280.8,61.9 C301.1,61.9 317.9,76.3 321.8,95.4 L350.5,89.5 C343.9,57 315.2,32.6 280.8,32.6 C272.9,32.6 265.3,33.9 258.3,36.2 L267.5,64 Z M233.6,156.9 L253,135 C244.3,127.3 238.9,116.1 238.9,103.6 C238.9,91.1 244.4,79.9 253,72.3 L233.6,50.4 C218.9,63.4 209.6,82.5 209.6,103.7 C209.6,124.9 218.9,143.9 233.6,156.9 Z M321.8,112.1 C317.9,131.2 301,145.6 280.8,145.6 C276.2,145.6 271.7,144.8 267.5,143.4 L258.2,171.2 C265.3,173.6 272.9,174.9 280.8,174.9 C315.2,174.9 343.9,150.5 350.5,118 L321.8,112.1 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PaymentCardElo;
