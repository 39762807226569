import { Color, TextStyle, ThemeProps } from '../theme';
import styled from 'styled-components';
import legacyColors from '@legacy-components/theme/colors';

interface StyledTextProps extends ThemeProps {
  styleKey: string;
  color?: Color;
}

function computeTextStyle({ theme, color, styleKey }: StyledTextProps) {
  let textStyle = theme.textStyle[styleKey];

  // Default to dark when there is no color defined
  if (!color && !textStyle.color) {
    textStyle = { ...textStyle, color: theme.color.dark };
  }

  // Theme color
  if (!color && textStyle.color instanceof Function) {
    textStyle = { ...textStyle, color: textStyle.color({ theme }) };
  }

  // Override color when provided
  if (color) {
    textStyle = { ...textStyle, color: theme.color[color] };
  }

  return textStyle;
}

export const StyledText = styled.div<{ styleKey: TextStyle; color?: Color }>`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif, 'Segoe UI', Roboto,
    Ubuntu;
  letter-spacing: 0;
  ${({ theme, styleKey, color }) =>
    computeTextStyle({ theme, styleKey, color })}
`;

export interface LoadableTextProps extends ThemeProps, StyledTextProps {
  isLoading: boolean;
}

export const LoadableText = styled.div`
  background-color: ${legacyColors[Color.GRAY_300]};
  color: ${legacyColors[Color.GRAY_300]};
  height: 20px;
  width: max-content;
`;
