import { css } from 'styled-components';

import { ThemeProps } from '@legacy-components/theme';
import { StyledSelector } from '@legacy-components/theme/helper';

export type Size =
  | 'phoneS'
  | 'phoneM'
  | 'phoneL'
  | 'tabletS'
  | 'tabletL'
  | 'laptop'
  | 'laptopL'
  | 'desktop';

export type SizeByDevice = { [deviceSizeName in Size]: number };

type DeviceMedia<P extends object> = {
  [deviceSizeName in Size]: StyledSelector<P>;
};

export const sizes: SizeByDevice = {
  phoneS: 360,
  phoneM: 400,
  phoneL: 600,
  tabletS: 720,
  tabletL: 840,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const DEFAULT_BODY_FONT_SIZE = 16;

const deviceSizes: Size[] = [
  'phoneS',
  'phoneM',
  'phoneL',
  'tabletS',
  'tabletL',
  'laptop',
  'laptopL',
  'desktop',
];

export const deviceMedia: DeviceMedia<object> = deviceSizes.reduce(
  (acc: DeviceMedia<ThemeProps>, deviceSizeName: Size) => {
    acc[deviceSizeName] = ((first, ...interpolations) => css`
      @media (max-width: ${sizes[deviceSizeName] / DEFAULT_BODY_FONT_SIZE}em) {
        ${css(first, ...interpolations)};
      }
    `) as StyledSelector<ThemeProps>;
    return acc;
  },
  {} as DeviceMedia<ThemeProps>,
);
