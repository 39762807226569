import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import localFont from 'next/font/local';

const { breakpoints } = createTheme();

export const caveat = localFont({
  src: '../../public/assets/fonts/Caveat/Caveat-Regular.ttf',
  weight: '400',
  style: 'normal',
});

export const futuraNowText = localFont({
  src: [
    {
      path: '../../public/assets/fonts/Futura-Now-Text/FuturaNowText-Rg.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/assets/fonts/Futura-Now-Text/FuturaNowText-Bd.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/assets/fonts/Futura-Now-Text/FuturaNowText-Blk.otf',
      weight: '900',
      style: 'normal',
    },
  ],
});

export const merlodNorme = localFont({
  src: '../../public/assets/fonts/Merlod-Norme/MerlodNorme-Bold.otf',
  weight: '700',
  style: 'normal',
});

const typography: TypographyOptions = {
  fontFamily: [
    futuraNowText.style.fontFamily,
    merlodNorme.style.fontFamily,
    caveat.style.fontFamily,
  ].join(','),
  'h1.merlodnorme': {
    fontFamily: merlodNorme.style.fontFamily,
    fontSize: '76px',
    fontWeight: 700,
    letterSpacing: '0.1em',
    lineHeight: '82px',
    textTransform: 'uppercase',
    [breakpoints.down('md')]: {
      fontSize: '28px',
      lineHeight: '35px',
    },
  },
  'h1.caveat': {
    fontFamily: caveat.style.fontFamily,
    fontSize: '76px',
    fontWeight: 500,
    lineHeight: '82px',
    [breakpoints.down('md')]: {
      fontSize: '36px',
      lineHeight: '45px',
    },
  },
  'h1.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '76px',
    fontWeight: 400,
    lineHeight: '82px',
    [breakpoints.down('md')]: {
      fontSize: '36px',
      lineHeight: '45px',
    },
  },
  'h2.merlodnorme': {
    fontFamily: merlodNorme.style.fontFamily,
    fontSize: '54px',
    fontWeight: 700,
    letterSpacing: '0.1em',
    lineHeight: '64px',
    textTransform: 'uppercase',
    [breakpoints.down('md')]: {
      fontSize: '36px',
      lineHeight: '45px',
    },
  },
  'h2.caveat': {
    fontFamily: caveat.style.fontFamily,
    fontSize: '54px',
    fontWeight: 500,
    lineHeight: '64px',
  },
  'h2.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '54px',
    fontWeight: 400,
    lineHeight: '64px',
  },
  'h3.merlodnorme': {
    fontFamily: merlodNorme.style.fontFamily,
    fontSize: '36px',
    fontWeight: 400,
    lineHeight: '45px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'h3.caveat': {
    fontFamily: caveat.style.fontFamily,
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '45px',
    [breakpoints.down('md')]: {
      fontSize: '28px',
      lineHeight: '35px',
    },
  },
  'h3.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '36px',
    fontWeight: 400,
    lineHeight: '45px',
  },
  'h4.merlodnorme': {
    fontFamily: merlodNorme.style.fontFamily,
    fontSize: '28px',
    fontWeight: 400,
    lineHeight: '35px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'h4.caveat': {
    fontFamily: caveat.style.fontFamily,
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '35px',
  },
  'h4.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '28px',
    fontWeight: 400,
    lineHeight: '35px',
    [breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  'h5.merlodnorme': {
    fontFamily: merlodNorme.style.fontFamily,
    fontSize: '28px',
    fontWeight: 400,
    lineHeight: '35px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  'h5.caveat': {
    fontFamily: caveat.style.fontFamily,
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '34px',
  },
  'h5.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '34px',
  },
  'h6.merlodnorme': {
    fontFamily: merlodNorme.style.fontFamily,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    [breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  'h6.caveat': {
    fontFamily: caveat.style.fontFamily,
    fontSize: '20px',
    fontWeight: 500,
  },
  'h6.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '30px',
    [breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '20px',
    },
  },
  'h7.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
    [breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  'h7.caveat': {
    fontFamily: caveat.style.fontFamily,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28px',
  },
  'h8.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    [breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  'h8.caveat': {
    fontFamily: caveat.style.fontFamily,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  'h9.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    [breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  'h10.futuranowtext': {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  yum: {
    fontFamily: merlodNorme.style.fontFamily,
    fontWeight: 700,
    fontSize: '550px',
    lineHeight: '420px',
    letterSpacing: '11px',
    textTransform: 'uppercase',
    [breakpoints.down('md')]: {
      fontSize: '225px',
      lineHeight: '180px',
      letterSpacing: 0.69,
    },
  },
  button: {
    fontFamily: futuraNowText.style.fontFamily,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
};

export default typography;
