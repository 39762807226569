export enum Color {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
  DANGER = 'danger',
  SUCCESS = 'success',
  SUNNY = 'sunny',
  LIGHT = 'light',
  DARK = 'dark',
  DISABLED = 'disabled',
  POULTRY = 'poultry',
  GLUTEN_FREE = 'glutenFree',
  RED_MEAT = 'redMeat',
  SEA_SHELL = 'seaShell',
  LACTOSE_FREE = 'lactoseFree',
  PORK = 'pork',
  RABBIT = 'rabbit',
  VEGGIE = 'veggie',
  SPICY = 'spicy',
  ALCOHOL = 'alcohol',
  TABLE_ROW = 'tableRow',
  EXPIRATION_SALE_BACKGROUND = 'expirationSaleBackground',

  /** Alternatives */
  PRIMARY_ALT = 'primaryAlt',
  SECONDARY_ALT = 'secondaryAlt',
  TERTIARY_ALT = 'tertiaryAlt',
  QUATERNARY_ALT = 'quaternaryAlt',
  SUNNY_ALT = 'sunnyAlt',
  DARK_ALT = 'darkAlt',
  SUCCESS_ALT = 'successAlt',
  DANGER_ALT = 'dangerAlt',

  /** Grays */
  GRAY_100 = 'gray100',
  GRAY_200 = 'gray200',
  GRAY_300 = 'gray300',
  GRAY_400 = 'gray400',
  GRAY_500 = 'gray500',
  /** @deprecated use GRAY_300 instead */
  GRAY = 'gray',
  /** @deprecated use GRAY_500 instead */
  DARKER_GRAY = 'darkerGray',

  /** Lightened versions */
  PRIMARY_LIGHT = 'primaryLight',
  SECONDARY_LIGHT = 'secondaryLight',
  TERTIARY_LIGHT = 'tertiaryLight',
  QUATERNARY_LIGHT = 'quaternaryLight',
  DANGER_LIGHT = 'dangerLight',
  SUCCESS_LIGHT = 'successLight',
  SUNNY_LIGHT = 'sunnyLight',
  DARK_LIGHT = 'darkLight',
  DISABLED_LIGHT = 'disabledLight',
  POULTRY_LIGHT = 'poultryLight',
  GLUTEN_FREE_LIGHT = 'glutenFreeLight',
  RED_MEAT_LIGHT = 'redMeatLight',
  SEA_SHELL_LIGHT = 'seaShellLight',
  LACTOSE_FREE_LIGHT = 'lactoseFreeLight',
  PORK_LIGHT = 'porkLight',
  RABBIT_LIGHT = 'rabbitLight',
  VEGGIE_LIGHT = 'veggieLight',
  SPICY_LIGHT = 'spicyLight',
  ALCOHOL_LIGHT = 'alcoholLight',

  /** Social */
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
}

export type FoodColor =
  | Color.POULTRY
  | Color.GLUTEN_FREE
  | Color.RED_MEAT
  | Color.SEA_SHELL
  | Color.LACTOSE_FREE
  | Color.PORK
  | Color.RABBIT
  | Color.VEGGIE
  | Color.SPICY
  | Color.ALCOHOL;

export type PrimaryColor =
  | FoodColor
  | Color.PRIMARY
  | Color.SECONDARY
  | Color.SECONDARY_ALT
  | Color.TERTIARY
  | Color.QUATERNARY
  | Color.QUATERNARY_LIGHT
  | Color.DANGER
  | Color.DANGER_LIGHT
  | Color.SUCCESS
  | Color.SUCCESS_LIGHT
  | Color.SUNNY
  | Color.SUNNY_LIGHT
  | Color.LIGHT
  | Color.DARK
  | Color.GRAY_200
  | Color.SUCCESS_ALT
  | Color.DANGER_ALT;

export type ColorDictionary = { [key in Color]: string };

export type LegacyPalette = {
  accent: Record<string, string>;
  gray: Record<string, string>;
};
