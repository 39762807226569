'use client';

import { ReactNode } from 'react';
import legacyTheme from '@legacy-components/theme';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import InitColorSchemeScript from '@mui/material/InitColorSchemeScript';

import GlobalStyles from '../GlobalStyles';
import StyledComponentsRegistry from '../StyledComponentsRegistry';
import theme from '../theme';

const themeWithLegacy = {
  ...legacyTheme,
  ...theme,
};

const AppThemeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <InitColorSchemeScript attribute="class" />
      <CssBaseline />
      <StyledComponentsRegistry>
        <MuiThemeProvider theme={themeWithLegacy}>
          <GlobalStyles />
          {children}
        </MuiThemeProvider>
      </StyledComponentsRegistry>
    </>
  );
};

export default AppThemeProvider;
