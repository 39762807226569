import get from 'lodash/get';
import omit from 'lodash/omit';
import { ComponentType, ReactElement } from 'react';
import styled, {
  DefaultTheme,
  Interpolation,
  RuleSet,
  StyleFunction,
  StyledObject,
  ThemeProvider,
  css,
} from 'styled-components';

import theme, { Color, TextStyle, ThemeProps } from '.';
import muiTheme from '@app/theme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

type Styles<Props extends object> =
  | TemplateStringsArray
  | StyledObject<Props>
  | StyleFunction<Props>;

type FlattenSimpleInterpolation<Props extends object> = RuleSet<Props>;

type ResponsiveSize =
  | 'phoneS'
  | 'phoneM'
  | 'phoneL'
  | 'tabletS'
  | 'tabletL'
  | 'laptop'
  | 'laptopL'
  | 'desktop';

export type SizeByDevice = { [deviceSizeName in ResponsiveSize]: number };

export type StyledSelector<Props extends object> = (
  first: Styles<NoInfer<Props>>,
  ...interpolations: Interpolation<NoInfer<Props>>[]
) => FlattenSimpleInterpolation<NoInfer<Props>>;

type DeviceMedia<Props extends object> = {
  [deviceSizeName in ResponsiveSize]: StyledSelector<Props>;
};

export const sizes: SizeByDevice = {
  phoneS: 360,
  phoneM: 400,
  phoneL: 600,
  tabletS: 720,
  tabletL: 840,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const DEFAULT_BODY_FONT_SIZE = 16;

const deviceSizes = [
  'phoneS',
  'phoneM',
  'phoneL',
  'tabletS',
  'tabletL',
  'laptop',
  'laptopL',
  'desktop',
];

const DEFAULT_EXCLUDED_PROPS: string[] = [
  'theme',
  'innerReference',
  'borderLess',
  'withLargeText',
  'maxFontSizeMultiplier',
];

export const deviceMedia: DeviceMedia<object> = deviceSizes.reduce(
  (acc: DeviceMedia<object>, deviceSizeName: ResponsiveSize) => {
    acc[deviceSizeName] = (first, ...interpolations) => css`
      @media (max-width: ${sizes[deviceSizeName] / DEFAULT_BODY_FONT_SIZE}em) {
        ${css(first, ...interpolations)};
      }
    `;
    return acc;
  },
  {} as DeviceMedia<object>,
);

export function withTheme(component: ReactElement): ReactElement {
  return (
    <ThemeProvider theme={theme as unknown as DefaultTheme}>
      <MuiThemeProvider theme={muiTheme}>{component}</MuiThemeProvider>
    </ThemeProvider>
  );
}

/**
 * Unfortunately styled-components is not always good at filtering props down.
 * To avoid warnings and errors especially on browsers this helper function will
 * help filtering non expected DOM props.
 * Use special props `innerReference` to pass down the reference to the special ref prop.
 *
 * @see https://github.com/styled-components/styled-components/issues/135
 * @param Component
 * @param excludedProps
 */
export function styledWithoutProps<P extends object>(
  Component: ComponentType<Partial<P>>,
  ...excludedProps: string[]
): ReturnType<typeof styled> {
  const CleanedComponent = (props: P): ReactElement => {
    const cleanedProps: Partial<P> = omit(props, [
      ...DEFAULT_EXCLUDED_PROPS,
      ...excludedProps,
    ]);
    return (
      <Component ref={get(props, 'innerReference', null)} {...cleanedProps} />
    );
  };
  CleanedComponent.displayName = Component.displayName;

  return styled(CleanedComponent);
}

type SupportedPlatformOSType = 'android' | 'ios' | 'web';

type PlatformSelector = SupportedPlatformOSType | 'mobile';

const supportedPlatformSpecifics: SupportedPlatformOSType[] = [
  'android',
  'ios',
  'web',
];

type PlatformSpecific<P extends object> = {
  [platformSelector in PlatformSelector]: StyledSelector<P>;
};

function makeSelector<Props extends object>(
  condition: boolean,
): StyledSelector<Props> {
  return (first, ...interpolations) => {
    return condition ? css(first, ...interpolations) : [];
  };
}

export const platformSpecific: PlatformSpecific<object> = {
  ...supportedPlatformSpecifics.reduce(
    (
      acc: PlatformSpecific<ThemeProps>,
      platformType: SupportedPlatformOSType,
    ) => {
      acc[platformType] = makeSelector(true);
      return acc;
    },
    {} as PlatformSpecific<object>,
  ),
  mobile: makeSelector(false),
};

export type ThemeSelector = ({ theme }: ThemeProps) => string;

export type SizeableProperty = 'radius' | 'spacing' | 'border';

export enum Size {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export function colorFromTheme(color: Color): ThemeSelector {
  return (props: ThemeProps): string => props.theme.color[color];
}

function sizeableFromTheme(
  size: Size,
  sizeableProperty: SizeableProperty,
): ThemeSelector {
  return (props: ThemeProps): string => props.theme[sizeableProperty][size];
}

export function radiusFromTheme(size: Size): ThemeSelector {
  return sizeableFromTheme(size, 'radius');
}

export function spacingFromTheme(size: Size): ThemeSelector {
  return sizeableFromTheme(size, 'spacing');
}

export function borderFromTheme(size: Size): ThemeSelector {
  return sizeableFromTheme(size, 'border');
}

export type AdaptiveTextStyleOptions = {
  iosStyleKey?: TextStyle;
  androidStyleKey?: TextStyle;
  mobileStyleKey?: TextStyle;
  webStyleKey?: TextStyle;
};

export function adaptiveTextStyle(
  styleKey: TextStyle,
  options?: AdaptiveTextStyleOptions,
): TextStyle {
  if (options?.webStyleKey) return options.webStyleKey;
  return styleKey;
}
