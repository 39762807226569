import { createTheme, Shadows } from '@mui/material';

const customShadows: Shadows = [...createTheme().shadows];

customShadows[2] =
  '0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 3px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.10)';
customShadows[4] =
  '0 2px 4px 0 rgba(0, 0, 0, 0.06), 0 4px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.10)';
customShadows[6] =
  '0 1px 18px 0 rgba(0, 0, 0, 0.06), 0 6px 10px 0 rgba(0, 0, 0, 0.06), 0 3px 5px 0 rgba(0, 0, 0, 0.10)';
customShadows[8] =
  '0 3px 14px 0 rgba(0, 0, 0, 0.06), 0 8px 10px 0 rgba(0, 0, 0, 0.06), 0 5px 5px 0 rgba(0, 0, 0, 0.10)';
customShadows[16] =
  '0 6px 30px 0 rgba(0, 0, 0, 0.06), 0 16px 24px 0 rgba(0, 0, 0, 0.06), 0 8px 10px 0 rgba(0, 0, 0, 0.10)';
customShadows[24] =
  '0 9px 46px 0 rgba(0, 0, 0, 0.06), 0 24px 38px 0 rgba(0, 0, 0, 0.06), 0 11px 15px 0 rgba(0, 0, 0, 0.10)';

export default customShadows;
