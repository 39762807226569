'use client';
import React, { ReactNode, useEffect } from 'react';
import * as notificationHelper from '@app-lib/common/helper/notification';
import * as toastify from 'react-toastify';
import ThemedToastContainer from '@legacy/common/components/ThemedToastContainer';

const ModalProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    notificationHelper.setupNotifier(
      (
        message: string,
        type?: notificationHelper.NotificationType,
      ): void | Promise<void> => {
        if (!type) {
          toastify.toast(message, { position: 'bottom-left' });
          return;
        }
        toastify.toast(message, { type, position: 'bottom-left' });
      },
    );
  }, []);

  return (
    <>
      {children}
      <ThemedToastContainer />
    </>
  );
};

export default ModalProvider;
