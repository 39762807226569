import {
  alpha,
  ButtonTypeMap,
  ColorShade,
  getContrastRatio,
  Theme,
} from '@mui/material';

export function getContrastTextColor(
  theme: Theme,
  color: ButtonTypeMap['props']['color'],
  shade: ColorShade = 'main',
): string | undefined {
  if (!color || color === 'inherit') {
    return undefined;
  }
  if (shade === 'main') {
    return theme.palette[color].contrastText;
  }
  return getContrastRatio(
    theme.palette[color][shade],
    theme.palette.common.white,
  ) >= theme.palette.contrastThreshold
    ? theme.palette.common.white
    : theme.palette.common.black;
}

export function getButtonColor(
  theme: Theme,
  color: ButtonTypeMap['props']['color'],
  shade: ColorShade = 'main',
): string | undefined {
  return color && color !== 'inherit' ? theme.palette[color][shade] : undefined;
}

export function getButtonLighterShadeColor(
  theme: Theme,
  color: ButtonTypeMap['props']['color'],
  shade: ColorShade,
): string {
  if (!color || color === 'inherit') {
    return '';
  }
  if (shade === 'main') {
    return theme.palette[color].medium;
  }
  if (shade === 'medium') {
    return theme.palette[color].pastel;
  }
  return alpha(theme.palette[color].pastel, 0.25);
}

export function getLighterShadeColor(
  theme: Theme,
  color: ButtonTypeMap['props']['color'],
  shade: ColorShade,
): string {
  if (!color || color === 'inherit') {
    return '';
  }
  if (shade === 'main') {
    return theme.palette[color].medium;
  }
  if (shade === 'medium') {
    return theme.palette[color].pastel;
  }
  return theme.palette[color].pastel;
}
