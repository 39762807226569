import { CSSProperties, MouseEventHandler, PropsWithChildren } from 'react';

import { Color, TextStyle } from '../theme';
import { LoadableText, StyledText } from './styledComponents';

export interface Props {
  styleKey?: TextStyle;
  color?: Color;
  isLoading?: boolean;
  className?: string;
  numberOfLines?: number;
  ellipsizeMode?: string;
  onPress?: MouseEventHandler;
  accessibilityLabel?: string;
  style?: CSSProperties | any;
  testID?: string;
}

const Text = ({
  children,
  styleKey = TextStyle.BODY_1,
  color,
  isLoading,
  onPress,
  accessibilityLabel,
  style,
  testID,
  ...textProps
}: PropsWithChildren<Props>) => {
  return isLoading ? (
    <LoadableText
      className={textProps.className}
      color={color}
      {...textProps}
      style={style}
    >
      {children}
    </LoadableText>
  ) : (
    <StyledText
      data-testid={testID}
      className={textProps.className}
      styleKey={styleKey}
      color={color}
      onClick={onPress}
      {...textProps}
      style={style}
    >
      {children}
    </StyledText>
  );
};

export default Text;
