import { ReactElement } from 'react';
import { ThemedSymbolProps } from './typings';
import { Color } from '../../theme';

const PromoCode = ({
  theme,
  active,
  color,
  ...svgProps
}: ThemedSymbolProps): ReactElement => (
  <svg {...svgProps}>
    <g fill={theme.color[active ? color || Color.PRIMARY : Color.GRAY_300]}>
      <path d="M4.57502 8.28499C4.8489 8.01111 4.99975 7.64693 4.99975 7.25964C4.99975 6.87234 4.84891 6.50818 4.57502 6.23429C4.30115 5.96041 3.93697 5.80957 3.54968 5.80957C3.16238 5.80957 2.79822 5.9604 2.52433 6.23429C2.25045 6.50817 2.09961 6.87234 2.09961 7.25964C2.09961 7.64693 2.25044 8.01109 2.52433 8.28487C2.79821 8.55875 3.16238 8.7096 3.54968 8.7096C3.93709 8.7096 4.30113 8.55876 4.57502 8.28499ZM3.06852 7.25964C3.06852 7.13108 3.11858 7.0103 3.2094 6.91937C3.30034 6.82843 3.42111 6.77848 3.54967 6.77848C3.67823 6.77848 3.79901 6.82855 3.88994 6.91937C3.98088 7.0103 4.03083 7.13108 4.03083 7.25964C4.03083 7.3882 3.98076 7.50897 3.88994 7.59991C3.79901 7.69084 3.67823 7.74079 3.54967 7.74079C3.42111 7.74079 3.30034 7.69073 3.2094 7.5998C3.11858 7.50897 3.06852 7.3882 3.06852 7.25964Z" />
      <path d="M7.45202 9.71216C7.06472 9.71216 6.70057 9.86299 6.42667 10.1369C6.15279 10.4108 6.00195 10.7749 6.00195 11.1622C6.00195 11.5495 6.15279 11.9137 6.42667 12.1876C6.70931 12.4702 7.08061 12.6116 7.45202 12.6116C7.82332 12.6116 8.19462 12.4703 8.47726 12.1876C8.75114 11.9137 8.90198 11.5495 8.90198 11.1622C8.90198 10.7749 8.75114 10.4108 8.47726 10.137C8.20338 9.863 7.83932 9.71216 7.45202 9.71216ZM7.79218 11.5025C7.60458 11.6901 7.29934 11.6901 7.11164 11.5025C7.02071 11.4116 6.97076 11.2908 6.97076 11.1622C6.97076 11.0337 7.02082 10.9129 7.11164 10.822C7.20258 10.731 7.32335 10.6811 7.45191 10.6811C7.58047 10.6811 7.70125 10.7311 7.79218 10.8221C7.88311 10.913 7.93307 11.0338 7.93307 11.1623C7.93317 11.2908 7.88311 11.4116 7.79218 11.5025Z" />
      <path d="M7.73616 6.97516C7.54705 6.78595 7.2403 6.78595 7.05108 6.97516L3.30793 10.7183C3.11871 10.9074 3.11871 11.2142 3.30793 11.4034C3.40254 11.498 3.52645 11.5453 3.65047 11.5453C3.77449 11.5453 3.8984 11.498 3.99301 11.4034L7.73616 7.66024C7.92527 7.47113 7.92527 7.16438 7.73616 6.97528V6.97516Z" />
      <path d="M10.1081 2.89439L6.65973 0.377567C5.97075 -0.125211 5.04436 -0.125958 4.35474 0.375945L0.8942 2.89418C0.334318 3.3016 0 3.95834 0 4.65078V13.0395C0 14.325 1.04589 15.3709 2.33148 15.3709H8.66852C9.95411 15.3709 11 14.325 11 13.0395V4.64939C11 3.95814 10.6666 3.30204 10.1082 2.89454L10.1081 2.89439ZM10.0311 13.0394C10.0311 13.7907 9.41978 14.402 8.66843 14.402H2.33111C1.57974 14.402 0.968432 13.7907 0.968432 13.0394V4.65068C0.968432 4.26696 1.15365 3.90321 1.46385 3.67746L4.92439 1.15933C5.09836 1.03272 5.30208 0.969463 5.50578 0.969463C5.70992 0.969463 5.91417 1.03304 6.08825 1.16009L9.53661 3.67691C9.84607 3.90267 10.0308 4.26619 10.0308 4.64917L10.0311 13.0394Z" />
      <path d="M6.03383 2.77614C6.03383 3.05844 5.80504 3.28725 5.52283 3.28725C5.24052 3.28725 5.01172 3.05845 5.01172 2.77614C5.01172 2.49394 5.24051 2.26514 5.52283 2.26514C5.80503 2.26514 6.03383 2.49393 6.03383 2.77614Z" />
    </g>
  </svg>
);

export default PromoCode;
