import styled, { css } from 'styled-components';

import Touchable from '../Touchable';
import Text from '../Text';

import { containerWidth, Sizeable } from '../dimension.helper';
import { Color, em, ThemeProps } from '../theme';
import { radiusFromTheme, Size } from '../theme/helper';
import { ButtonProps } from './typings';

interface ContainerProps extends Sizeable, ThemeProps {
  backgroundColor: Color;
  borderColor: Color;
  tiny: boolean;
  small: boolean;
  large: boolean;
  outlined: boolean;
  inGroupRight: boolean;
  inGroupCenter: boolean;
  inGroupLeft: boolean;
  cappedHeight?: boolean;
  light?: boolean;
}

function buttonHeight({
  tiny,
  small,
}: Pick<ContainerProps, 'tiny' | 'small'>): string {
  if (tiny) return em(2);
  if (small) return em(2.5);
  return em(3);
}

export const TouchableContainer = styled(Touchable)<ContainerProps>`
  width: ${containerWidth};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 ${em(1)};

  ${({
    theme,
    outlined,
    borderColor,
    backgroundColor,
    tiny,
    small,
    cappedHeight,
    light,
  }: ContainerProps) => css`
    background-color: ${theme.color[backgroundColor]};
    ${outlined &&
    `border: ${theme.border.medium} solid ${theme.color[borderColor]}`};
    height: ${buttonHeight({ tiny, small })};
    ${cappedHeight && 'max-height: 100%;'}
    ${light &&
    `box-shadow: 0 ${theme.shadow.small} ${theme.shadow.small} ${
      theme.color[Color.GRAY_200]
    };`};
  `}
  border-radius: ${radiusFromTheme(Size.SMALL)};
  display: inline-flex;
  box-sizing: border-box;
  ${({ inGroupRight, inGroupCenter, inGroupLeft }: ContainerProps) => {
    if (inGroupRight) {
      return css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `;
    }
    if (inGroupCenter) {
      return css`
        border-radius: 0;
      `;
    }
    if (inGroupLeft) {
      return css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `;
    }
    return null;
  }}
`;

export function symbolOnlyContainerSize(
  small: boolean,
  large: boolean,
): number {
  if (small) return 1.5;
  if (large) return 2.5;
  return 2;
}

export const SymbolOnlyTouchableContainer = styled(
  TouchableContainer,
)<ContainerProps>`
  ${({ large, small }: ContainerProps) => css`
    height: ${em(symbolOnlyContainerSize(small, large))};
    width: ${em(symbolOnlyContainerSize(small, large))};
  `};
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type SymbolContainerProps = {
  symbolOnly: boolean;
  small: boolean;
  large: boolean;
};

export const SymbolContainer = styled.div<SymbolContainerProps>`
  ${({ symbolOnly }: SymbolContainerProps) => css`
    width: ${em(1.25)};
    height: ${em(1.25)};
    margin-right: ${symbolOnly ? '0' : em(1)};
  `};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: ${em(-0.25)};
  right: ${em(-0.25)};
`;

export const WithHelperTextContainer = styled(
  TouchableContainer,
)<ContainerProps>`
  flex-direction: column;
  min-width: ${em(8.5)};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

interface HelperTextProps {
  bold?: boolean;
}

export const HelperText = styled(Text)<HelperTextProps>`
  ${({ bold }: HelperTextProps) => css`
    font-weight: ${bold ? 'bold' : 'normal'};
    line-height: 1.33;
  `}
`;

interface RowWrapperProps {
  hidden: boolean;
}

export const RowWrapper = styled.div<RowWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${({ hidden }: RowWrapperProps) => css`
    height: ${hidden ? 0 : 'auto'};
  `}
`;

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface StyledTextProps extends Partial<ButtonProps>, ThemeProps {}

export const StyledText = styled(Text)<StyledTextProps>``;
