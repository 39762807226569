import isEmpty from 'lodash/isEmpty';
import { ReactElement } from 'react';
import MuiButton from '@mui/material/Button';

import Badge from '../Badge';
import Symbol from '../Symbol';
import { Color } from '../theme';
import ButtonWithHelperText from './ButtonWithHelperText';
import {
  colorAssociation,
  SMALL_SYMBOL_SCALE,
  MEDIUM_SYMBOL_SCALE,
  LARGE_SYMBOL_SCALE,
  DEFAULT_COLOR,
  getTextStyleKey,
} from './helper';
import {
  BadgeContainer,
  StyledText,
  SymbolContainer,
} from './styledComponents';
import { ButtonProps } from './typings';
import legacyColors from '@legacy-components/theme/colors';
import { useTheme } from 'styled-components';

const Button = ({
  title,
  width,
  fullWidth = false,
  color = DEFAULT_COLOR,
  symbolKey,
  tiny = false,
  small = false,
  large = false,
  outlined = false,
  inGroupRight = false,
  inGroupCenter = false,
  inGroupLeft = false,
  notificationCounter,
  textStyle,
  hasBoldText,
  testID,
  onPress,
  onClick,
  disabled,
  className,
  accessibilityLabel,
  ...touchableContainerProps
}: ButtonProps): ReactElement => {
  const theme = useTheme();
  let symbolScale: number = small ? SMALL_SYMBOL_SCALE : MEDIUM_SYMBOL_SCALE;
  symbolScale = large ? LARGE_SYMBOL_SCALE : symbolScale;
  const symbolOnly: boolean = !!symbolKey && isEmpty(title);
  const { textColor, backgroundColor } = colorAssociation({
    color,
    disabled,
    light: touchableContainerProps.light,
  });

  let size: 'small' | 'large' | 'medium' = 'large';
  if (tiny) {
    size = 'small';
  }
  if (small) {
    size = 'medium';
  }

  let borderRadius = {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  };

  if (inGroupRight) {
    borderRadius = {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    };
  }
  if (inGroupCenter) {
    borderRadius = {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    };
  }
  if (inGroupLeft) {
    borderRadius = {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    };
  }
  const { fontFamily, fontSize, fontWeight } =
    theme.textStyle[getTextStyleKey({ textStyle, tiny })];
  return (
    <MuiButton
      aria-label={accessibilityLabel}
      variant={outlined ? 'outlined' : 'contained'}
      data-testid={testID}
      onClick={onClick ?? onPress}
      fullWidth={fullWidth}
      size={size}
      className={className}
      disabled={disabled}
      disableRipple
      sx={{
        borderColor: legacyColors[textColor as Color],
        backgroundColor: legacyColors[backgroundColor],
        width,
        padding: '16px 24px',
        height: touchableContainerProps.cappedHeight ? '100%' : undefined,
        textTransform: 'none',
        fontFamily,
        fontSize,
        fontWeight,
        color: legacyColors[textColor as Color],
        ...borderRadius,
      }}
      style={{ outline: 'none' }}
    >
      {notificationCounter && notificationCounter > 0 && (
        <BadgeContainer>
          <Badge counter={notificationCounter} />
        </BadgeContainer>
      )}
      {symbolKey && (
        <SymbolContainer symbolOnly={symbolOnly} large={large} small={small}>
          <Symbol
            symbolKey={symbolKey}
            scale={symbolScale}
            color={textColor as Color}
          />
        </SymbolContainer>
      )}
      {title && (
        <StyledText
          styleKey={getTextStyleKey({ textStyle, tiny })}
          hasBoldText={!!hasBoldText}
          style={{
            color: legacyColors[textColor as Color],
          }}
        >
          {title}
        </StyledText>
      )}
    </MuiButton>
  );
};

Button.WithHelperText = ButtonWithHelperText;

export default Button;
